/* global ajax_object */
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Swiper slider
import Swiper, {
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  EffectCoverflow,
  Thumbs,
  Lazy,
} from 'swiper';

Swiper.use([
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
  EffectCoverflow,
  Lazy,
]);

// Import local dependencies
// eslint-disable-next-line
import './plugins/lazyload';
import './plugins/modernizr.min';
// import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
// eslint-disable-next-line
// import lazyLoadInstance from './plugins/lazyload';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

$('.menu-icon').on('click', function () {
  $(this).toggleClass('is-active');
  $('.header-menu').slideToggle();
  $('body').toggleClass('body-menu-active');
});

function textCalc(arr, width, height, fontSizes) {
  $(arr).each(function (idx, el) {
    let dementions = (width / height) * 100,
      eldementions = ($(el).width() / $(el).height()) * 100,
      childrens = $(el).children(),
      percentage,
      fontSize;

    if (eldementions < dementions) {
      percentage = (eldementions * 100) / dementions;
    }
    $(childrens).each(function (idx, el) {
      fontSize = (fontSizes[idx] * percentage) / 100;
      $(el).css('font-size', `${fontSize}` + 'px');
    });
  });
}

let textCalcWrappers = $('div[data-text-width]'),
  textCalcWrapperWidth,
  textCalcWrapperHeight,
  fontSizeChidrens = [];

textCalcWrappers.each(function (idx, el) {
  let childrens = $(el).children();
  $(childrens).each(function (idx, el) {
    let elFontSize = $(el).css('font-size'),
      fontSize = +elFontSize.replace('px', '');
    fontSizeChidrens.push(fontSize);
  });
});

let scrollToNextSection = function (arr) {
  $(arr).each(function (idx, el) {
    $(el).on('click', function () {
      let nextSection = $(this).parent('section').next('section');
      $('html, body').animate(
        { scrollTop: nextSection.offset().top - 100 },
        1500
      );
    });
  });
};

const popupGallery = function (arr) {
  $(arr).each(function (idx, el) {
    $(el)
      .siblings()
      .on('click', function () {
        $(el).slideDown('');
        $('body').css('overflow', 'hidden');
      });
    $(el)
      .children('.pop-up__gallery--close')
      .on('click', function () {
        $(el).slideUp('');
        $('body').css('overflow', 'initial');
      });
  });
};

/**
 * Scripts which runs after DOM load
 */
function menuFunction(items, event, breakpoint) {
  let link = items.find('.first-row-link');
  if (event === 'hover') {
    link.hover(function () {
      let $this = $(this); // a.first-row-link
      $this.siblings('.submenu-wrapper').addClass('is-open').slideDown('300');
      $this.siblings('.has-children-arrow').addClass('arrow-up');
    });
  }

  if (event === 'click') {
    $(items)
      .children('.has-children-arrow')
      .on('click', function () {
        let currentItem = $(this).parent('.menu-item');
        if (currentItem.hasClass('menu-item-has-children')) {
          currentItem
            .children('.submenu-wrapper')
            .slideToggle()
            .toggleClass('is-open');
          $(this).toggleClass('arrow-up');
        }
      });
  }

  items.on('mouseleave', function () {
    let $this = $(this);
    if ($this.children('.submenu-wrapper').hasClass('is-open')) {
      $this.children('.submenu-wrapper').slideUp('100').removeClass('is-open');
      $this.children('.has-children-arrow').removeClass('arrow-up');
    }
  });

  if (window.innerWidth < breakpoint) {
    items.on('click', function () {
      let $this = $(this);
      $this
        .children('.submenu-wrapper')
        .toggleClass('is-open')
        .slideToggle('300');
      $this.children('.has-children-arrow').toggleClass('arrow-up');
    });
  }
}

$(document).on('ready', function () {
  let menuItems = $('.header-menu').find('.menu-item-has-children');
  menuFunction(menuItems, 'hover', 1024);
  // menuFunctioanlity(menuItems, 'hover');
  $('.pop-up__gallery').each(function () {
    popupGallery($(this));
  });

  scrollToNextSection([$('.home-hero__scroll-btn'), $('.test-btn')]);

  textCalcWrapperWidth = textCalcWrappers.width();
  textCalcWrapperHeight = textCalcWrappers.height();

  // Ajax projects load
  function projectsLoad(cat, offset, add) {
    const data = {
      action: 'projects_load',
      nonce: ajax_object.nonce,
      cat: cat,
      offset: offset,
      add: add,
    };

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      dataType: 'json',
      data,
      success: function (response) {
        if (cat) {
          $('.page-portfolio__portfolio__inner').html(response.html);
        } else {
          $('.page-portfolio__portfolio__inner').append(response.html);
        }
      },
      error: function (e) {
        console.log('error', e);
      },
    });
  }
  let btnLoad = $('#projects_load');
  let amountProjects = btnLoad.data('amount'),
    offsetProjects = btnLoad.data('offset'),
    addProjects = 3;
  btnLoad.on('click', function (e) {
    e.preventDefault();
    let cats = $('#projects-terms').find('.project-terms'),
      cat = '';
    $(cats).each(function (idx, el) {
      if ($(el).hasClass('is-active')) {
        cat = $(el).data('term');
      }
    });

    projectsLoad(cat === 'all' ? '' : cat, offsetProjects, addProjects);
    offsetProjects += addProjects;
    if (offsetProjects >= amountProjects) {
      $(this).hide();
    }
  });

  $('#projects-terms')
    .find('.project-terms')
    .each(function (idx, el) {
      if (idx === 0) {
        $(el).addClass('is-active');
      }
      $(el).on('click', function () {
        let cat = $(this).data('term');
        $(this).siblings().removeClass('is-active');
        $(this).addClass('is-active');
        projectsLoad(cat);
      });
    });

  const singleProjectSlider = new Swiper('.single-project__slider', {
    loop: true,
    effect: 'slide',
    autoplay: true,
    slidesPerView: 1,
    grabCursor: true,
    allowTouchMove: true,
    preloadImages: false,
    lazy: true,
    watchSlidesProgress: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  });
  singleProjectSlider.init();

  const projectSlider = new Swiper('.projects-swiper', {
    spaceBetween: 30,
    loop: true,
    effect: 'slide',
    slidesPerView: 1,
    freeMode: true,
    grabCursor: false,
    allowTouchMove: true,
    // disableOnInteraction: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  });
  projectSlider.init();

  const homeCompanies = new Swiper('.mobile-companies-slider', {
    loop: true,
    effect: 'slide',
    grabCursor: true,
    initialSlide: 2,
    allowTouchMove: true,
    preloadImages: true,
    // centeredSlidesBounds: true,
    autoplay: {
      delay: 2000,
    },
    lazy: false,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'bullets',
    // },
    breakpoints: {
      320: {
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 30,
      },
      641: {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 30,
      },
    },
  });
  homeCompanies.init();
  // homeCompanies.on('slideChange', function () {
  //   lazyLoadInstance.update();
  // });

  const popUpSlider1 = new Swiper('.mySwiper', {
    loop: true,
    spaceBetween: 30,
    slidesPerView: 4,
    centeredSlides: false,
    lazy: true,
    preloadImages: true,
    freeMode: false,
    grabCursor: true,
    watchSlidesProgress: true,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      641: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });
  popUpSlider1.init();
  popUpSlider1.on('slideChange', function () {
    popUpSlider2.slideTo(popUpSlider1.activeIndex);
  });
  // popUpSlider1.on('slideChange', function () {
  //   lazyLoadInstance.update();
  // });
  const popUpSlider2 = new Swiper('.mySwiper2', {
    effect: 'coverflow',
    grabCursor: true,
    loop: true,
    lazy: true,
    centeredSlides: true,
    preloadImages: true,
    slidesPerView: 2,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    thumbs: {
      swiper: popUpSlider1,
    },
  });
  popUpSlider2.init();
  popUpSlider2.on('slideChange', function () {
    popUpSlider1.slideTo(popUpSlider2.activeIndex);
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  // $(window)
  //   .on('toggled.zf.responsiveToggle', function () {
  //     $('.menu-icon').toggleClass('is-active');
  //   })
  //   .on('changed.zf.mediaquery', function () {
  //     $('.menu-icon').removeClass('is-active');
  //   });

  /**
   * Close responsive menu on orientation change
   */
  // $(window).on('orientationchange', function () {
  //   setTimeout(function () {
  //     if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
  //       $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
  //     }
  //   }, 200);
  // });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  textCalc(
    textCalcWrappers,
    textCalcWrapperWidth,
    textCalcWrapperHeight,
    fontSizeChidrens
  );
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  if ($(window).scrollTop() >= 50) {
    $('.header__inner--top').slideUp(100);
    $('.header__inner--bot').addClass('scroll-header');
    // $('.header').css('box-shadow', '0 5px 5px rgba(43,151,224, 0.5)');
  } else {
    $('.header__inner--top').slideDown(100);
    $('.header__inner--bot').removeClass('scroll-header');
    $('.header').css('box-shadow', 'initial');
  }
});
